import * as React from "react"
import Layout from "../components/layout"

const Equipe = () => {
  // to declare new team just add another object in the team array
  const team = [
    {
      name: "Saad Laraichi",
      functionIn: "CEO, Entrepreneur",
      img: "/saad-laraichi.svg",
      description: "Saad est diplômé de Télécom Paris et titulaire d'un master à HEC Paris. Fort de cinq années d’expérience dans le conseil et la data science, Saad nourrit depuis plusieurs années des ambitions entrepreneuriales particulièrement dans le domaine de la santé.",
      linkedIn: "https://www.linkedin.com/in/sa%C3%A2d-laraichi-b3121564"
    },
    {
      name: "Karim Guessous",
      functionIn: "MD, Médecin anesthésiste-réanimateur",
      img: "/karim-guessous.svg",
      description: "Interne en anesthésie réanimation à Paris VI et féru de nouvelles technologies, Karim travaille pour leur intégration dans les pratiques médicales, notamment au travers de son master en ingénierie biomédicale à l’ESPCI et aux Arts et Métiers.",
      linkedIn: "https://www.linkedin.com/in/karim-guessous-269556140"
    },
    {
      name: "Nabil Zanoun",
      functionIn: "Medical Advisor, Médecin anesthésiste-réanimateur",
      img: "/nabil-zanoun.svg",
      description: "MAR au sein d’un hôpital privé, Dr Zanoun souhaite intégrer les nouvelles technologies dans l’organisation du bloc et participe à le bonne compréhension métier du bloc opératoire.",
      linkedIn: "https://www.linkedin.com/in/nabil-zanoun-2669351a2"
    },

    {
      name: "Michael Mouity Nzamba",
      functionIn: "Head of Sales",
      img: "/Michael.png",
      description: "Diplômé de l'ESCP et de l'Université Paris 1 Panthéon-Sorbonne, Michaël parcourt l'univers des startups depuis 2014, et plus spécifiquement dans la santé depuis 2018.Motivé par les projets à fort impact social, il considère que la santé est le domaine idéal pour déployer les technologies garantissant une amélioration des conditions de vie des soignants, une meilleure efficacité des fonctionnements internes et, in fine, un meilleur soin offert aux patients.",
      linkedIn: "https://www.linkedin.com/in/micha%C3%ABl-mouity-nzamba/"
    },

    {name: "Azzeddine Nacer",
      functionIn: "Full-Stack Developer",
      img: "/azzeddine2.png",
      description: "Titulaire d'un master en Informatique à Sorbonne Université (ex UPMC), Azzeddine participe à la conception et au développement des outils et fonctionnalités qui répondent aux besoins des clients.",
      linkedIn: "https://www.linkedin.com/in/azzeddine-nacer-05a4a1192/"
    }
  ]
  const description =
    "SmartOp est née d’une seule ambition : vouloir améliorer le quotidien des soignants et garantir une meilleure prise en charge des patients. Grâce à son expérience du bloc opératoire, l’équipe fondatrice de SmartOp a tenté de retranscrire les problématiques métiers pour fournir un nouvel outil qui facilite le quotidien des équipes soignantes."
  return (
    <Layout>
      <div className="equipe-container">
        <div className="description-container">
          <div className="title">Nos valeurs et notre équipe</div>
          <div className="subtitle">{description}</div>
        </div>

        <div className="card-container">
          {team.map(({ name, functionIn, img, description, linkedIn }) => (
            <div className="card-wrapper">
              <div className="card">
                <img className="mg-b-16" src={img} alt={name} />
                <div className="title mg-b-2">{name}</div>
                <div className="subtitle mg-b-20">{functionIn}</div>
                <div className="description mg-b-16">{description}</div>
                <div className="linkedin-btn">
                  <a href={linkedIn} target="_blank">LinkedIn</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default Equipe
